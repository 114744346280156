import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../components/PageLayout";

// testing

// Comment  

export default function Projects({ data }) {
  console.log(data);
  let items = [];
  debugger;
  for (let i = 0; i < data.allMiSmisArtistsListItem.nodes.length; i++) {
    const item = data.allMiSmisArtistsListItem.nodes[i].data.fields;
    items.push(
      <div className="artist">
        <div>
          <img src={item.ImageUrl} width="100" alt={item.Title} />
        </div>
        <div>
          <h2>{item.Title}</h2>
          <h3>Genre: {item.Genre}</h3>

        </div>
      </div>



    );


  }
  return (<div>
    <PageLayout>
      <h1>Projects</h1>
      <div className="artists">
        {items}
      </div>
    </PageLayout>
  </div>);
}
// (filter: {data: {fields: {Genre: {eq: "Christian"}}}})
export const query = graphql`
query MyQuery {
  allMiSmisArtistsListItem {
    nodes {
      data {
        fields {
          Title
          ImageUrl
          Genre
          Bio
        }
      }
    }
  }
}
`;